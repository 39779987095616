import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
   users: reducers.UsuariosState,
   products: reducers.ProductState,
   categories: reducers.CategoriesState,
   series: reducers.SeriesState,
   nomenclature: reducers.NomenclatureState,
   basket: reducers.BasketState,
}

export const appReducers: ActionReducerMap<AppState> = {
   products: reducers.ProductReducer,
   users: reducers.UsuarioReducer,
   categories: reducers.CategoriesReducer,
   series: reducers.SeriesReducer,
   nomenclature: reducers.NomenclatureReducer,
   basket: reducers.BasketsReducer
}