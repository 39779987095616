import { createAction, props } from "@ngrx/store";
import { ISeries } from "../../shared/Models/ISeries";
import { IPagniationSeries } from "src/app/shared/Models/Pagniation";

let ApiActions: string = "Series";
export const UPLOAD_ALL_SERIES = createAction(
  "[" + { ApiActions } + "] Upload all Series to state"
);
export const UPLOAD_ALL_SERIES_SUCCESS = createAction(
  "[" + { ApiActions } + "] Upload all Series to Success",
  props<{ series: IPagniationSeries }>()
);
export const UPLOAD_ALL_SERIES_ERROR = createAction(
  "[" + { ApiActions } + "] Upload all Series to Error",
  props<{ payload: any }>()
);
export const SELECTED_SERIES = createAction(
  "[" + { ApiActions } + "] Select Series to list",
  props<{ series: ISeries }>()
);
export const DELETE_SELECTED_SERIES = createAction(
  "[" + { ApiActions } + "] Delete select series to list",
);
