import { createReducer, on } from "@ngrx/store";
import * as ActionsProducts from "../actions";
import { INomenclature } from "src/app/shared/Models/nomenclature";


export interface NomenclatureState {
    nomenclatureSelected: INomenclature, 
    loaded : boolean,
    loading: boolean,
    error  : any
}
export const nomenclatureInitialState: NomenclatureState = {
    nomenclatureSelected:null,
    loaded:false,
    loading:false,
    error: ''
}

const _NomenclatureReducer = createReducer(
    nomenclatureInitialState,
    on(ActionsProducts.UPLOAD_ALL_SERIES_ERROR, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message,
        },
    })),
    on(ActionsProducts.UPLOAD_NUMENCLATURE_SUCCESS, (state, { charact }) => ({
        ...state,
        nomenclatureSelected: charact
    })),
    
);

export function NomenclatureReducer(state, action) {
    return _NomenclatureReducer(state, action);
}
        

