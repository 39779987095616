 import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, pipe } from "rxjs";
import {
  IPagniationCategory,
  IPagniationProduct,
  IPagniationSeries,
} from "../shared/Models/Pagniation";
import { IProducts } from "../shared/Models/Products";
import {  ShopParamsProduct } from "../shared/Models/shopParams";
import { environment } from "../../environments/environment";
import { IUser } from "../shared/Models/user";
import { ISeries } from "../shared/Models/ISeries";
import { ISeals } from '../shared/Models/seals';

@Injectable({
  providedIn: "root",
})
export class StoreService {
  _baseURL = environment.baseURl;

  constructor(private http: HttpClient) {}

  async getProduct(shopParams: ShopParamsProduct): Promise<IPagniationProduct> {
    let params = new HttpParams();
    if (shopParams.categoryId !== 0) {
      params = params.append("categoryId", shopParams.categoryId);
    }
    if (shopParams.search) {
      params = params.append("search", shopParams.search);
    }
    params = params.append("sort", shopParams.sort);
  
    params = params.append("pageNumber", shopParams.pageNumber);
    params = params.append("pageSize", shopParams.pageSize);
  
    return this.http
      .get<IPagniationProduct>(this._baseURL + "products/get-for-page-product", {
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise(); // Convierte el observable en una promesa
  }
  
  async getCategory(): Promise<IPagniationCategory> {
    return this.http
      .get<IPagniationCategory>(
        this._baseURL + "Categories/get-all-categories",
        { observe: "response" }
      )
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise(); // Convierte el observable en una promesa
  }
  async getSeries(): Promise<IPagniationSeries> {
    return this.http
      .get<IPagniationSeries>(this._baseURL + "series/get-all-series", {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise(); // Convierte el observable en una promesa
  }
  async getSealsById(id: number): Promise<ISeals> {
    return this.http
      .get<ISeals>(this._baseURL + "seals/get-seals-by-id/"+ id, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise(); // Convierte el observable en una promesa
  }
    async getSealsTypeById(id: number): Promise<ISeals> {
    return this.http
      .get<ISeals>(this._baseURL + "sealsType/get-sealsType-by-id/"+ id, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response.body;
        })
      )
      .toPromise(); // Convierte el observable en una promesa
  }
  getProductId(id: number) {
    return this.http.get<IProducts>(
      this._baseURL + "products/get-product-by-id/" + id
    );
  }
  getUserId(id: string) {
    return this.http.get<IUser>(
      this._baseURL + "account/check-user-id-exis/" + id
    );
  }
}
