import { createAction, props } from '@ngrx/store';
import { ICategory } from '../../shared/Models/Category';
import { ShopParamsProduct } from 'src/app/shared/Models/shopParams';

let ApiActions:string ='Categories'


export const UPLOAD_ALL_CATEGORIES = createAction(
    '['+{ApiActions}+'] Start upload all Categories',
);

export const UPLOAD_ALL_CATEGORIES_SUCCESS = createAction(
    '['+{ApiActions}+'] Upload all Categories Success',
    props<{ categories: any }>()
);

export const UPLOAD_ALL_CATEGORIES_ERROR = createAction(
    '['+{ApiActions}+'] Upload all Categories Error',
    props<{ payload: any }>()
);

export const SELECTED_CATEGORIES = createAction(
    '['+{ApiActions}+'] Select Categories to list',
     props<{ categories: ICategory }>()
);
export const SELECTED_PARAMETERS_PRODUCTS = createAction(
    '['+{ApiActions}+'] Select parameters in state',
     props<{ param: ShopParamsProduct }>()
);
export const ACTIVE_SELECTED_PARAMETERS = createAction(
    '['+{ApiActions}+'] Active select parameters ',
    props<{  model: ShopParamsProduct  }>()

);
export const CHANGES_LOADED = createAction(
    '['+{ApiActions}+'] Change loaded category ',
    props<{  load: boolean  }>()

);
export const DELETE_SELECTED_CATEGORY= createAction(
    '['+{ApiActions}+'] Delete select categories to list',
);