import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {  catchError, switchMap, withLatestFrom } from "rxjs/operators";
import { from, of } from "rxjs";
import { StoreService } from "../../services/store.services";
import * as nomenclatureActions from "../actions";
import * as productsSelectors from "../selectors/index";
import { NomenclatureService } from "src/app/services/nomenclature.services";
import { Store } from '@ngrx/store';
import { AppState } from "../app.reducers";
import { INomenclature } from "src/app/shared/Models/nomenclature";
import { ISeals } from "src/app/shared/Models/seals";
import { ISealsType } from '../../shared/Models/sealsType';

@Injectable()
export class NomenclatureEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private storeService: StoreService, private nomenclatureService: NomenclatureService) {}

 /* uploadNomenclature$ = createEffect(() =>
  this.actions$.pipe(
    ofType(nomenclatureActions.ACTIVATE_GET_NOMENCLATURE),
    withLatestFrom(this.store.select(productsSelectors.selectProductSelected)),
    switchMap(([action, seriesState]) => {
      debugger;
      if (seriesState.id) {
        return from(this.nomenclatureService.getNomenclatureByIdProduct(seriesState.id)).pipe(
          switchMap((nom: INomenclature) => {
            // Dispatch the success action for INomenclature
            debugger;
            this.store.dispatch(
              nomenclatureActions.UPLOAD_NUMENCLATURE_SUCCESS({
                charact: nom,
              })
            );

            // Fetch Seals data
            return from(this.storeService.getSealsById(nom.SealsId)).pipe(
              switchMap((seals: ISeals) => {
                // Dispatch the success action for ISeals
                this.store.dispatch(
                  nomenclatureActions.UPLOAD_NUMENCLATURE_SEALS_SUCCESS({
                    charact: seals,
                  })
                );

                // Fetch SealsType data
                return from(this.storeService.getSealsTypeById(nom.SealsId)).pipe(
                  switchMap((sealsType: ISealsType) => {
                    // Dispatch the success action for ISealsType
                    this.store.dispatch(
                      nomenclatureActions.UPLOAD_NUMENCLATURE_SEALSTYPE_SUCCESS({
                        charact: sealsType,
                      })
                    );
                    
                    // Return an observable here
                    return of(); // Or any other observable as needed
                  }),
                  catchError((err) =>
                    of(nomenclatureActions.UPLOAD_NUMENCLATURE_SEALSTYPE_ERROR({ payload: err }))
                  )
                );
              }),
              catchError((err) =>
                of(nomenclatureActions.UPLOAD_NUMENCLATURE_SEALS_ERROR({ payload: err }))
              )
            );
          }),
          catchError((err) =>
            of(nomenclatureActions.UPLOAD_NOMENCLATURES_ERROR({ payload: err }))
          )
        );
      } else {
        // Return an observable for the error action
        return of(nomenclatureActions.UPLOAD_NOMENCLATURES_ERROR({ payload: true }));
      }
    })
  )
);*/
}
