import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BasketService } from 'src/app/services/basket.service';
import { IBasket, IBasketItem } from 'src/app/shared/Models/basket';
import { Store } from '@ngrx/store';
import * as basketSelectors from '../../../../../store/selectors/basket.selectors'


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
 
  public openCart: boolean = false;
  readonly basket$: Observable<IBasketItem[]> = this.store.select(
    basketSelectors.selectBasket
  );
  constructor(private basketService:BasketService, private store: Store) { }

  ngOnInit(): void {
    /*this.basket$ = this.basketService.basket$;*/
  }
  // For Mobile Device
  toggleCart() {
    this.openCart = !this.openCart;
  }

}
