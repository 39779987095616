import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as productsActions from "../actions";
import {
  mergeMap,
  map,
  catchError,
  withLatestFrom,
  switchMap,
  tap,
} from "rxjs/operators";
import { StoreService } from "../../services/store.services";
import { NomenclatureService } from "../../services/nomenclature.services";
import { of, from } from "rxjs";
import { IProducts } from "../../shared/Models/Products";
import { Store } from "@ngrx/store";
import {
  IPagniationCategory,
  IPagniationProduct,
  IPagniationSeries,
} from "src/app/shared/Models/Pagniation";
import { AppState } from "../app.reducers";
import * as productsSelectors from "../selectors/products.selectors";
import * as categorySelectors from "../selectors/category.selectors";
import * as seriesSelectors from "../selectors/series.selectors";
import { ShopParamsProduct } from "src/app/shared/Models/shopParams";
import { ISeries } from "src/app/shared/Models/ISeries";

@Injectable()
export class ProductsEffects {
  constructor(
    private actions$: Actions,
    private storeService: StoreService,
    private nomenclatureService: NomenclatureService,
    private store: Store<AppState>
  ) {}
  uploadProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.UPLOAD_PRODUCT_ID),
      mergeMap((action) =>
        this.storeService.getProductId(action?.id).pipe(
          map((product: IProducts) =>
            productsActions.UPLOAD_PRODUCT_ID_SUCCESS({ product })
          ),
          catchError((err) =>
            of(productsActions.UPLOAD_PRODUCT_ID_ERROR({ payload: err }))
          )
        )
      )
    )
  );
  uploadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.UPLOAD_ALL_PRODUCTS),
      withLatestFrom(
        this.store.select(productsSelectors.selectProductState),
        this.store.select(productsSelectors.selectParameterSearch)
      ),
      switchMap(([action, productState, search]) => {
        if (!productState.loaded) {
          return from(
            this.storeService.getProduct(productState.parameters)
          ).pipe(
            switchMap((product: IPagniationProduct) => {
              if (product) {
                return of(
                  productsActions.UPLOAD_ALL_PRODUCT_SUCCESS({
                    IProducts: product,
                  })
                );
              } else {
                return of(
                  productsActions.UPLOAD_ALL_PRODUCT_ERROR({ payload: true })
                );
              }
            }),
            catchError((err) =>
              of(productsActions.UPLOAD_ALL_PRODUCT_ERROR({ payload: true }))
            )
          );
        } else {
          return of(
            productsActions.UPLOAD_ALL_PRODUCT_ERROR({ payload: true })
          );
        }
      })
    )
  );
  changesModels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productsActions.ACTIVE_UPLOAD_PRODUCTS),
        withLatestFrom(
          this.store.select(productsSelectors.selectProductsSwitch),
          this.store.select(productsSelectors.selectParameterSearch),
          this.store.select(categorySelectors.selectCategorySelected),
          this.store.select(seriesSelectors.selectSerieSelected)
        ),
        tap(([action, param, search, category, series]) => {
          const params = new ShopParamsProduct();
          if (param.changeCategory && !param.changeSeries && search == "") {
            params.categoryId = category.id;
            this.store.dispatch(
              productsActions.SELECTED_PARAMETERS_PRODUCTS({ param: params })
            );
            this.store.dispatch(
              productsActions.CHANGES_PRODUCTS_FOR_CATEGORY({
                param: params,
              })
            );
            return of(
              this.store.dispatch(
                productsActions.UPLOAD_ALL_PRODUCTS({
                  param: null,
                })
              )
            );
          } else if (
            param.changeSeries &&
            !param.changeCategory &&
            search == ""
          ) {
            params.serieId = series.id;
            this.store.dispatch(
              productsActions.SELECTED_PARAMETERS_PRODUCTS({ param: params })
            );
            this.store.dispatch(
              productsActions.CHANGES_PRODUCTS_FOR_SERIES({ param: params })
            );
            return of(
              this.store.dispatch(
                productsActions.UPLOAD_ALL_PRODUCTS({
                  param: null,
                })
              )
            );
          } else if (search !== "") {
            return of(
              this.store.dispatch(
                productsActions.UPLOAD_ALL_PRODUCTS({
                  param: params,
                })
              )
            );
          } else {
            this.store.dispatch(productsActions.CHANGES_PARAMETERS_PRODUCTS());
            return of(
              this.store.dispatch(
                productsActions.UPLOAD_ALL_PRODUCTS({
                  param: params,
                })
              )
            );
          }
          // Manejar otros casos si es necesario
        })
      ),
    { dispatch: false } // Indica que no se espera un valor de acción en este efecto
  );
  uploadCharacteristics$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.SELECTED_CHARACTERISTICS),
      withLatestFrom(
        this.store.select(productsSelectors.selectProductSelected),
        this.store.select(categorySelectors.selectCategory),
        this.store.select(seriesSelectors.selectSerie)
      ),
      mergeMap(([action, product, category, serie]) => {
        let categorys: any;
        if (category) {
          category.forEach((element) => {
            if (element.id === product.categoryId) {
              categorys = element;
              return of(
                this.store.dispatch(
                  productsActions.UPLOAD_CHARACTERISTICS_PRODUCT_CATEGORY({
                    charact: element,
                  })
                )
              );
            }
          });
        }
        if (serie) {
          serie.forEach((element: ISeries) => {
            if (categorys?.serieId == element.id) {
              return of(
                this.store.dispatch(
                  productsActions.UPLOAD_CHARACTERISTICS_PRODUCT_SERIES({
                    charact: element,
                  })
                )
              );
            }
          });
        }
        return of({ type: "UPLOAD_CHARACTERISTICS_SUCCESS" });
      })
    )
  );
  uploadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.UPLOAD_ALL_CATEGORIES),
      withLatestFrom(this.store.select(categorySelectors.selectCategoryState)),
      switchMap(([action, categoryState]) => {
        if (!categoryState.loaded) {
          return from(this.storeService.getCategory()).pipe(
            switchMap((cat: IPagniationCategory) => {
              if (cat) {
                return of(
                  productsActions.UPLOAD_ALL_CATEGORIES_SUCCESS({
                    categories: cat,
                  })
                );
              } else {
                return of(
                  productsActions.UPLOAD_ALL_CATEGORIES_ERROR({ payload: true })
                );
              }
            }),
            catchError((err) =>
              of(productsActions.UPLOAD_ALL_CATEGORIES_ERROR({ payload: err }))
            )
          );
        } else {
          return of(
            productsActions.UPLOAD_ALL_CATEGORIES_ERROR({ payload: true })
          );
        }
      })
    )
  );
  uploadSeries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productsActions.UPLOAD_ALL_SERIES),
      withLatestFrom(this.store.select(seriesSelectors.selectSerieState)),
      switchMap(([action, seriesState]) => {
        if (!seriesState.loaded) {
          return from(this.storeService.getSeries()).pipe(
            switchMap((series: IPagniationSeries) => {
              if (series) {
                return of(
                  productsActions.UPLOAD_ALL_SERIES_SUCCESS({ series })
                );
              } else {
                return of(
                  productsActions.UPLOAD_ALL_SERIES_ERROR({ payload: true })
                );
              }
            }),
            catchError((err) =>
              of(productsActions.UPLOAD_ALL_SERIES_ERROR({ payload: err }))
            )
          );
        } else {
          return of(productsActions.UPLOAD_ALL_SERIES_ERROR({ payload: true }));
        }
      })
    )
  );
 
}
