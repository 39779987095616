import { createReducer, on } from '@ngrx/store';
import * as BasketProducts from "../actions/basket.actions";
import { IBasket, IBasketItem } from 'src/app/shared/Models/basket';

export interface BasketState {
    basketSelected: IBasket, 
    basket: IBasketItem[],
    loaded : boolean,
    loading: boolean,
    error  : any

}
export const BasketInitialState: BasketState = {
    basketSelected  :null,
    basket  : [],
    loaded : false,
    loading: false,
    error  : null
}

const _BasketsReducer = createReducer(
    BasketInitialState,
    on(BasketProducts.UPLOAD_ALL_BASKET, (state) => ({ ...state, loading: true })),
    on(BasketProducts.ADD_PRODUCT_TO_BASKET, ( state, { basket  } ) => ({ 
        ...state, 
        basket: [...state.basket,
            ...basket]
    })),
);

export function BasketsReducer(state, action) {
    return _BasketsReducer(state, action);
}
