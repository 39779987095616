import { Injectable } from "@angular/core";
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducers';
import { StoreService } from '../../services/store.services';
import { BasketService } from "../../services/basket/basket.service";

@Injectable()
export class BasketEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private storeService: StoreService, private nomenclatureService: BasketService) {}

}