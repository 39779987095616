import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: "Welcome",
    },
    {
      title: "Home",
      icon: "bonus-kit",
      type: "sub",
      badgeValue: "3",
      badgeType: "light-primary",
      active: true,
      path:"/home",
      children: [
        { path: "/home", title: "Home", type: "link" },
        { path: "home/login", title: "Login", type: "link" },
        { path: "home/registrer", title: "Registrer", type: "link" },
      ],
    },
    {
      title: "Store",
      type: "sub",
      icon: "ecommerce",
      badgeType: "light-primary",
      badgeValue: "10",
      active: false,
      children: [
        { path: "/store/products", title: "Offers", type: "link" },
        { path: "/store/product-details", title: "Product:", type: "link" },
        { path: "/store/product/list", title: "Product list", type: "link" },
        { path: "/store/payment/detail", title: "Payment Details", type: "link" },
        { path: "/store/order", title: "Orders", type: "link" },
        { path: "/store/invoice", title: "Invoice", type: "link" },
        { path: "/store/cart", title: "Cart", type: "link" },
        { path: "/store/wish-list", title: "Wishlist", type: "link" },
        { path: "/store/checkout", title: "Checkout", type: "link" },
        { path: "/store/pricing", title: "Pricing", type: "link" },
      ],
    },
    {
      title: "Selector",
      icon: "knowledgebase",
      type: "link",
      badgeType: "light-primary",
      active: true,
      badgeValue: "2",
      path:"/selector",
      children: [
        { path: "/selector", title: "selector", type: "link" },
        { path: "/configuration", title: "configuration", type: "link" },
      ],
    },
    {
      title: "Users",
      icon: "user",
      active: true,
      badgeType: "light-primary",
      badgeValue: "2",
      path:"/user/profile",
      type: "link",
      children: [
        { path: "/user/profile", title: "Users", type: "link" },
        { path: "/user/edit-profile", title: "Edit Profile", type: "link" },
      ],
    },
    {
      title: "Admin",
      icon: "ui-kits",
      type: "sub",
      active: false,
      children: [
        { path: "/user/team-details", title: "All Users", type: "link" },
      ],
    },
    { icon: "search", title: "search", type: "link", bookmark: true },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
