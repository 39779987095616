<swiper [direction]="'vertical'" [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <ng-template data-swiper-autoplay="2500" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/giftools.gif" alt="gif" />
      <h6 class="mb-0 f-w-400"><span class="font-primary">Don't Miss Out! </span><span class="f-light">Out new update has been release.</span></h6>
      <i class="icon-arrow-top-right f-light"></i>
    </div>
  </ng-template>
  <ng-template data-swiper-autoplay="5000" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/giftools.gif" alt="gif" />
      <h6 class="mb-0 f-w-400"><span class="f-light">new models Pumps with offers, Click here, </span></h6>
      <a class="ms-1"  target="_blank">Buy now !</a>
    </div>
  </ng-template>
</swiper>
