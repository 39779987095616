import { createReducer, on } from "@ngrx/store";
import * as ActionsProducts from "../actions";
import { ISeries } from "../../shared/Models/ISeries";


export interface SeriesState {
    serieSelected: ISeries, 
    series: any[],
    loaded : boolean,
    loading: boolean,
    error  : any
}
export const seriesInitial: ISeries = {
    id:0,
    name:""
}
export const seriesInitialState: SeriesState = {
    serieSelected  : null,
    series : [],
    loaded : false,
    loading: false,
    error  : null
}

const _SeriesReducer = createReducer(
    seriesInitialState,

    on(ActionsProducts.UPLOAD_ALL_SERIES, (state) => ({ ...state, loading: true })),

    on(ActionsProducts.UPLOAD_ALL_SERIES_SUCCESS, (state, { series  }) =>  ({
        ...state,
        loading: false,
        loaded: true,
        series: [...series.value],
    })),

    on(ActionsProducts.UPLOAD_ALL_SERIES_ERROR, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message,
        },
    })),
    on(ActionsProducts.SELECTED_SERIES, (state, { series  }) =>  ({
        ...state,
        serieSelected: series
    })),
    on(ActionsProducts.DELETE_SELECTED_SERIES, (state) =>  ({
        ...state,
        serieSelected: seriesInitial
    }))
);

export function SeriesReducer(state, action) {
    return _SeriesReducer(state, action);
}
