import {v4 as uuid } from "uuid";
export interface IBasket {
    id?: string;
    basketItems?: IBasketItem[];
    clientSecret?:string,
    paymentIntentId?:string,
    deliveryMethodId?:number,
    shippingPrice?:number
}

export interface IBasketItem {
    id: number;
    productName: string;
    productPicture: string;
    price: number;
    quantity: number;
    category: number;
}

export class BasketItem implements IBasketItem{
    id = 0;
    productName= '';
    productPicture= '';
    price= 0;
    quantity= 0;
    category= 0; 
}



export class Basket implements IBasket {
    id= uuid();
    basketItems: IBasketItem[]=[];

}

export interface IBasketTotals{
    shipping:number;
    subtotal:number;
    total:number;
}