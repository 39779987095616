import { Routes } from "@angular/router";
import { SelectorqtsModule } from '../../selectorqts/selectorqts.module';

export const content: Routes = [

  {
    path: "home",
    loadChildren: () => import("../../../app/auth/auth.module").then((m) => m.AuthModule) ,
  },
  {
    path: "selector",
    loadChildren: () => import("../../../app/selectorqts/selectorqts.module").then((m) => m.SelectorqtsModule) ,
  },
  {
    path: "store",
    loadChildren: () => import("../../components/apps/e-commerce/e-commerce.module").then((m) => m.ECommerceModule),
  },
  {
    path: "user",
    loadChildren: () => import("../../components/apps/users/users.module").then((m) => m.UsersModule),
  }
];
