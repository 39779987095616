<div class="modal-header product-modal">
  <div class="product-box row">
    <div class="product-img col-lg-6">
      <img class="img-fluid" [src]='productDetail.productPicture' alt="" />
    </div>
    <div  class="product-details col-lg-6 text-start">
      <h4>{{ productDetail?.name }}</h4>
      <div class="product-price">
        ${{ productDetail?.price }}
        <del>${{ productDetail?.price }}</del>
      </div>
      <div class="product-view">
        <h6 class="f-w-600">Product Details</h6>
        <p class="mb-0">{{ productDetail?.description }}</p>
      </div>
      <div class="product-qnty">
        <h6 class="f-w-600">Quantity</h6>
        <div class="input-group m-b-20">
          <button class="btn btn-primary btn-square bootstrap-touchspin-down" (click)="decrement()">
            <i class="fa fa-minus btnGtr1"></i>
          </button>
          <input class="touchspin text-center form-control" #qty name="quantity" type="text" value="{{ counter }}" />
          <button class="btn btn-primary btn-square bootstrap-touchspin-up" (click)="increment()">
            <i class="fa fa-plus btnLess1"></i>
          </button>
        </div>
        <div class="addcart-btn"><a class="btn btn-primary me-3" [routerLink]="['/store/cart']">Add to Cart</a><a class="btn btn-primary" [routerLink]="['/store/product-details']">View Details</a></div>
      </div>
    </div>
  </div>
</div>
