import { createAction, props } from '@ngrx/store';
import { IBasketItem } from 'src/app/shared/Models/basket';

let ApiActions:string ='Basket'

export const UPLOAD_ALL_BASKET = createAction(
    '['+ApiActions+'] Start upload all basket',
);
export const ADD_PRODUCT_TO_BASKET = createAction(
    '['+ApiActions+'] Start upload all basket',
    props<{ basket: IBasketItem[] }>()
);