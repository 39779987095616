import { createAction, props } from "@ngrx/store";
import { IUser } from "../../shared/Models/user";

export const UPLOAD_USERS_ID = createAction(
  "[Usuarios] Cargar Usuarios por id",
  props<{ id: string, }>()
);

export const UPLOAD_USERS_ID_SUCCESS = createAction(
  "[Usuarios] Cargar Usuarios Success",
  props<{ usuarios: IUser }>()
);

export const UPLOAD_USERS_ID_ERROR = createAction(
  "[Usuarios] Error al cargar los usuarios",
  props<{ payload: any }>()
);

export const UPLOAD_ALL_USERS = createAction(
  "[Usuarios] upload all users"
);

export const UPLOAD_ALL_USERS_SUCCESS = createAction(
  "[Usuarios] Cargar Usuarios Success",
  props<{ usuarios: IUser[] }>()
);

export const UPLOAD_ALL_USERS_ERROR = createAction(
  "[Usuarios] Error al cargar los usuarios",
  props<{ payload: any }>()
);