 import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { INomenclature } from "../shared/Models/nomenclature";
import { map } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NomenclatureService {
  _baseURL = environment.baseURl;

  constructor(private http: HttpClient) {}
  async getNomenclatureByIdProduct(id: number): Promise<INomenclature> {
    const response = await this.http
      .get<INomenclature>(this._baseURL + "nomenclature/get-models-nomenclature-by-productId/" + id, {
        observe: "response",
      })
      .toPromise();
    return response.body;
  }
}
