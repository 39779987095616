import { createReducer, on } from "@ngrx/store";
import * as ActionsProducts from "../actions";
import { IChangeModel, IPagniation } from "src/app/shared/Models/Pagniation";
import { ShopParamsProduct } from "src/app/shared/Models/shopParams";
import { IProducts } from "src/app/shared/Models/Products";
import { ICategory } from "src/app/shared/Models/Category";
import { ISeries } from "src/app/shared/Models/ISeries";
import { UPLOAD_CHARACTERISTICS_PRODUCT_SERIES } from '../actions/products.actions';

export interface ProductState {
  product: IProducts;
  products: IProducts[];
  pagination: IPagniation[];
  changeModels: IChangeModel;
  parameters: ShopParamsProduct;
  characteristics:DescriptionProduct;
  loaded: boolean;
  loading: boolean;
  error: any;
}
export interface DescriptionProduct {
    categoryModel: ICategory,
    serieModel: ISeries,
}
export const changeModel: IChangeModel = {
  changeSeries: false,
  changeCategory: false,
};
export const productInitialState: ProductState = {
  product: null,
  products: [],
  pagination: null,
  changeModels: changeModel,
  parameters: new ShopParamsProduct(),
  characteristics:undefined,
  loaded: false,
  loading: false,
  error: null,
};

const _ProductReducer = createReducer(
  productInitialState,
  on(ActionsProducts.UPLOAD_ALL_PRODUCTS, (state) => ({
    ...state,
    loading: true,
  })),

  on(ActionsProducts.UPLOAD_ALL_PRODUCT_SUCCESS, (state, { IProducts }) => ({
    ...state,
    loading: false,
    loaded: true,
    products: [...IProducts.data],
    pagination: [
      {
        pageNumber: IProducts.pageNumber,
        pageSize: IProducts.pageSize,
        count: IProducts.count,
      },
    ],
  })),

  on(ActionsProducts.UPLOAD_ALL_PRODUCT_ERROR, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: false,
    error: {
      url: payload.url,
      name: payload.name,
      message: payload.message,
    },
  })),
  on(ActionsProducts.SELECTED_PRODUCT, (state, { product }) => ({
    ...state,
    product: product,
  })),
  on(ActionsProducts.CHANGES_LOADED, (state, { load }) => ({
    ...state,
    loaded: load,
  })),
  on(ActionsProducts.CHANGES_MODELS_FOR_CATEGORY, (state, { param }) => ({
    ...state,
    changeModels: {
      ...state.changeModels,
      changeCategory: param, // Modifica el valor según sea necesario
    },
  })),
  on(ActionsProducts.CHANGES_MODELS_FOR_SERIES, (state, { param }) => ({
    ...state,
    changeModels: {
      ...state.changeModels,
      changeSeries: param, // Modifica el valor según sea necesario
    },
  })),
  on(ActionsProducts.UPLOAD_CHARACTERISTICS_PRODUCT_CATEGORY, (state, { charact }) => ({
    ...state,
    characteristics: {
        ...state.characteristics,
        categoryModel: charact
      },
  })),
  on(ActionsProducts.UPLOAD_CHARACTERISTICS_PRODUCT_SERIES, (state, { charact }) => ({
    ...state,
    characteristics: {
        ...state.characteristics,
        serieModel: charact
      },
  })),
  on(ActionsProducts.SELECTED_PARAMETERS_PRODUCTS, (state, { param }) => ({
    ...state,
    parameters: param,
  })),
  on(ActionsProducts.CHANGES_PARAMETERS_PRODUCTS, (state) => ({
    ...state,
    parameters: new ShopParamsProduct(),
  })),
  on(ActionsProducts.SEARCH_PRODUCTS, (state, { search }) => ({
    ...state,
    parameters: {
      ...state.parameters,
      search: search,
    },
  }))
);

export function ProductReducer(state, action) {
  return _ProductReducer(state, action);
}
