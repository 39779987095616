import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as userActions from "../actions/users.actions";
import { tap, mergeMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { StoreService } from "../../../app/services/store.services";
import { IUser } from "../../../app/shared/Models/user";

@Injectable()
export class UsuariosEffects {
  constructor(private actions$: Actions, private storeService: StoreService) {}
  cargarUsuarios$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.UPLOAD_USERS_ID),
      mergeMap((action) => {
        try {
          return this.storeService.getUserId(action.id).pipe(
            map((user: IUser) =>
              userActions.UPLOAD_USERS_ID_SUCCESS({ usuarios: user })
            ),
            catchError((err) =>
              of(userActions.UPLOAD_USERS_ID_ERROR({ payload: err }))
            )
          );
        } catch (error) {
          console.error(error);
        }
      })
    )
  );
}
