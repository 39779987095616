import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CategoriesState } from "../reducers";
import { ICategory } from '../../shared/Models/Category';

export const selectCategoryState = createFeatureSelector<CategoriesState>('categories');

export const selectCategory = createSelector(
    selectCategoryState,
  (state: CategoriesState) : ICategory[] => state.categories
);
export const selectCategorySelected = createSelector(
    selectCategoryState,
  (state: CategoriesState) : ICategory => state.categorySelected
);

export const selectCategoryLoading = createSelector(
    selectCategoryState,
  (state:CategoriesState) => state.loading
);

export const selectCategoryError = createSelector(
    selectCategoryState,
  (state:CategoriesState):string => state.error
);