import { Component, Input, Output, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ShopParamsProduct,
  ShopParamsSeries,
} from "src/app/shared/Models/shopParams";
import { ICategory } from "src/app/shared/Models/Category";
import { ISeries } from "../../Models/ISeries";
import { QuickViewComponent } from "../quick-view/quick-view.component";
import { Observable } from "rxjs";
import { Product } from "src/app/components/interfaces/product";
import { IPagniation } from "../../Models/Pagniation";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.reducers";
import {
  ACTIVE_UPLOAD_PRODUCTS,
  ADD_PRODUCT_TO_BASKET,
  CHANGES_LOADED,
  CHANGES_MODELS_FOR_CATEGORY,
  CHANGES_MODELS_FOR_SERIES,
  DELETE_SELECTED_CATEGORY,
  DELETE_SELECTED_SERIES,
  SEARCH_PRODUCTS,
  SELECTED_CATEGORIES,
  SELECTED_PRODUCT,
  SELECTED_SERIES,
} from "src/app/store/actions";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as productsSelectors from '../../../store/selectors/index'
import { IProducts } from "../../Models/Products";
import { BasketItem, IBasketItem } from "../../Models/basket";

@Component({
  selector: "app-product-list-shared",
  templateUrl: "./product-list-shared.component.html",
  styleUrls: ["./product-list-shared.component.scss"],
  template: `<a (click)="openProductDetail()"></a>`,
})
export class ProductListSharedComponent implements OnInit {
  @Input("icon") public icon;
  @Input() callback: (value: string) => void;
  /* @Input() products: IProducts[];*/
  @Input() products$: Observable<Product[]>;
  @Input() category$: Observable<ICategory[]>;
  @Input() series$: Observable<ISeries[]>;
  @Input() pag$: Observable<IPagniation>;
  @Output() productDetail: IProducts;
  public listData: any = [];
  openSidebar: boolean = false;
  OpenFilter: Boolean = false;

  sidebaron: boolean = false;
  show: boolean = false;
  open: boolean = false;
  public listView: boolean = false;
  public col_xl_12: boolean = false;
  public col_xl_2: boolean = false;

  public col_sm_3: boolean = false;
  public col_xl_3: boolean = true;
  public xl_4: boolean = true;
  public col_sm_4: boolean = false;
  public col_xl_4: boolean = false;
  public col_sm_6: boolean = true;
  public col_xl_6: boolean = false;
  public gridOptions: boolean = true;
  totalCount: number;
  shopParams = new ShopParamsProduct();
  seriesParams = new ShopParamsSeries();
  selectedOption: string | null = null;
  selectedOption2: string | null = null;
  @ViewChild("quickView") QuickView: QuickViewComponent;
  readonly search$: Observable<string> = this.store.select(
    productsSelectors.selectParameterSearch
  );
  constructor(
    private modalService: NgbModal,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) { 
    this.searchForm = this.fb.group({
      search: ["", [Validators.required]],
    });
  }

  public searchForm: FormGroup;
  ngOnInit() {}
  searchProduct(){
    const valorSearch = this.searchForm.get('search').value;
    this.store.dispatch(SEARCH_PRODUCTS({ search: valorSearch }));
    this.store.dispatch(CHANGES_LOADED({load: false}));
    this.store.dispatch(ACTIVE_UPLOAD_PRODUCTS({model: null}));
  }
  clearSelection(event: any) {
    const type = event.currentTarget.ariaLabel;
    const model = event.currentTarget.value;
    this.store.dispatch(CHANGES_LOADED({load: false}));
    switch (type) {
      case "Category":
        // Procesar la selección de la categoría
        this.selectedOption = null; // Esto deseleccionará todas las opciones
        this.store.dispatch(CHANGES_MODELS_FOR_CATEGORY({ param: false }));
        this.store.dispatch(DELETE_SELECTED_CATEGORY());
        this.store.dispatch(ACTIVE_UPLOAD_PRODUCTS({ model }));
        break;
      case "Series":
        // Procesar la selección de la serie
        this.selectedOption2 = null; // Esto deseleccionará todas las opciones
        this.store.dispatch(CHANGES_MODELS_FOR_SERIES({ param: false }));
        this.store.dispatch(DELETE_SELECTED_SERIES());
        this.store.dispatch(ACTIVE_UPLOAD_PRODUCTS({ model }));
        break;
      default:
        // Manejar otros casos si es necesario
        break;
    }
  }
  UploadCart(product: IProducts, quantity: number){
    const basket:IBasketItem[] = this.AddProductToCart(product,quantity)
   debugger
    this.store.dispatch(ADD_PRODUCT_TO_BASKET({basket}));
  }

  AddProductToCart(product: IProducts, quantity: number){
    const basket = new BasketItem();
    basket.category = product.categoryId;  
    basket.price = product.price;  
    basket.productName = product.name;  
    basket.productPicture = product.pictures
    basket.quantity = quantity; 
    return [basket];
  }

  activationSelection(model: any, type: string) {
    this.store.dispatch(CHANGES_LOADED({load: false}));
    switch (type) {
      case "Category":
        this.store.dispatch(SELECTED_CATEGORIES({ categories: model }));
        this.store.dispatch(CHANGES_MODELS_FOR_CATEGORY({ param: true }));
        this.store.dispatch(ACTIVE_UPLOAD_PRODUCTS({ model }));

        break;
      case "Series":
        this.store.dispatch(SELECTED_SERIES({ series: model  }));
        this.store.dispatch(CHANGES_MODELS_FOR_SERIES({ param: true }));
        this.store.dispatch(ACTIVE_UPLOAD_PRODUCTS({ model }));
        break;
      default:
        // Manejar otros casos si es necesario
        break;
    }
  }
  onOptionSelected(event: any, type: string): void {
    this.activationSelection(event.value, type);
  }

  toggleListView(val) {
    this.listView = val;
  }

  sidebarToggle() {
    this.openSidebar = !this.openSidebar;
  }
  openFilter() {
    this.OpenFilter = !this.OpenFilter;
  }

  gridOpens() {
    this.listView = false;
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_3 = true;

    this.xl_4 = true;
    this.col_xl_4 = false;
    this.col_sm_4 = false;

    this.col_xl_6 = false;
    this.col_sm_6 = true;

    this.col_xl_2 = false;
    this.col_xl_12 = false;
  }
  listOpens() {
    this.listView = true;
    this.gridOptions = false;
    this.listView = true;
    this.col_xl_3 = true;
    this.xl_4 = true;
    this.col_xl_12 = true;
    this.col_xl_2 = false;

    this.col_xl_4 = false;
    this.col_sm_4 = false;
    this.col_xl_6 = false;
    this.col_sm_6 = true;
  }
  grid2s() {
    this.listView = false;
    this.col_xl_3 = false;
    this.col_sm_3 = false;

    this.col_xl_2 = false;

    this.col_xl_4 = false;
    this.col_sm_4 = false;

    this.col_xl_6 = true;
    this.col_sm_6 = true;

    this.col_xl_12 = false;
  }
  grid3s() {
    this.listView = false;
    this.col_xl_3 = false;
    this.col_sm_3 = false;

    this.col_xl_2 = false;
    this.col_xl_4 = true;
    this.col_sm_4 = true;

    this.col_xl_6 = false;
    this.col_sm_6 = false;

    this.col_xl_12 = false;
  }
  grid6s() {
    this.listView = false;
    this.col_xl_3 = false;
    this.col_sm_3 = false;

    this.col_xl_2 = true;
    this.col_xl_4 = false;
    this.col_sm_4 = false;

    this.col_xl_6 = false;
    this.col_sm_6 = false;

    this.col_xl_12 = false;
  }

  openProductDetail(content: any, item: IProducts) {
    if (this.callback) {
      this.callback("Hello from ComponentB");
    }
    this.store.dispatch(SELECTED_PRODUCT({ product: item }));
    this.productDetail = item;
    this.modalService.open(content, { centered: true, size: "lg" });
    console.log(this.productDetail);
  }

  ngDoCheck() {
    this.col_xl_12 = this.col_xl_12;
    this.col_xl_2 = this.col_xl_2;
    this.col_sm_3 = this.col_xl_12;
    this.col_xl_3 = this.col_xl_3;
    this.xl_4 = this.xl_4;
    this.col_sm_4 = this.col_sm_4;
    this.col_xl_4 = this.col_xl_4;
    this.col_sm_6 = this.col_sm_6;
    this.col_xl_6 = this.col_xl_6;
  }
}
