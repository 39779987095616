import { createReducer, on } from "@ngrx/store";
import * as ActionsProducts from "../actions";
import { ICategory } from "src/app/shared/Models/Category";


export interface CategoriesState {
    categorySelected: ICategory, 
    categories: ICategory[],
    loaded : boolean,
    loading: boolean,
    error  : any
}
export const categoriesInitial: ICategory = {
    id:0,
    description:'',
    name:'',
    SerieId:0
}
export const categoriesInitialState: CategoriesState = {
    categorySelected  :null,
    categories  : [],
    loaded : false,
    loading: false,
    error  : null
}

const _CategoriesReducer = createReducer(
    categoriesInitialState,

    on(ActionsProducts.UPLOAD_ALL_CATEGORIES, (state) => ({ ...state, loading: true })),

    on(ActionsProducts.UPLOAD_ALL_CATEGORIES_SUCCESS, (state, { categories  }) =>  ({
        ...state,
        loading: false,
        loaded: true,
        categories: [...categories.value],
    })),

    on(ActionsProducts.UPLOAD_ALL_CATEGORIES_ERROR, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message,
        },
    })),
    on(ActionsProducts.SELECTED_CATEGORIES, (state, { categories  }) =>  ({
        ...state,
        loading: false,
        loaded: true,
        categorySelected: categories
    })),
    on(ActionsProducts.DELETE_SELECTED_CATEGORY, (state) =>  ({
        ...state,
        loading: false,
        loaded: true,
        categorySelected: categoriesInitial
    }))
);

export function CategoriesReducer(state, action) {
    return _CategoriesReducer(state, action);
}
