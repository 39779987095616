import { createAction, props } from "@ngrx/store";
import { INomenclature } from "src/app/shared/Models/nomenclature";
import { ISeals } from "src/app/shared/Models/seals";
import { ISealsType } from "src/app/shared/Models/sealsType";

let ApiActions: string = "Products";

export const ACTIVATE_GET_NOMENCLATURE = createAction(
  "[" + ApiActions + "] Active get Nomenclature",
);
export const UPLOAD_NUMENCLATURE_SUCCESS = createAction(
  "[" + ApiActions + "] Upload Nomenclature success",
  props<{ charact: INomenclature }>()
);
export const UPLOAD_NUMENCLATURE_SEALS_SUCCESS = createAction(
  "[" + ApiActions + "] Upload Seals in Nomenclature success",
  props<{ charact: ISeals }>()
);
export const UPLOAD_NUMENCLATURE_SEALSTYPE_SUCCESS = createAction(
  "[" + ApiActions + "] Upload SealsType in Nomenclature success",
  props<{ charact: ISealsType }>()
);
export const UPLOAD_NUMENCLATURE_SEALSTYPE_ERROR = createAction(
  "[" + ApiActions + "] Upload SealsType in Nomenclature error",
  props<{ payload: any }>()
);
export const UPLOAD_NUMENCLATURE_SEALS_ERROR = createAction(
  "[" + ApiActions + "] Upload Seals in Nomenclature error",
  props<{ payload: any }>()
);
export const UPLOAD_NOMENCLATURES_ERROR = createAction(
  "[" + ApiActions + "] Upload Nomenclature error",
  props<{ payload: any }>()
);
