import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { LoginApiService } from "src/app/services/login-api.service";

import { IUser } from "src/app/shared/Models/user";


@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  currentUser$ : Observable<IUser>;
  
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router, public loginApi: LoginApiService ) {
   
  }

  ngOnInit(): void {
    this.currentUser$ = this.loginApi.currentUser$;
  }

  logoutFunc(){
    this.loginApi.logout();
  }
}
