import { createReducer, on } from '@ngrx/store';
import * as userActions from "../actions/users.actions";
import  { user } from '../../components/interfaces/user';
import { UPLOAD_ALL_USERS, UPLOAD_USERS_ID_ERROR } from '../actions/users.actions';

export interface UsuariosState {
    users  : user[],
    loaded : boolean,
    loading: boolean,
    error  : any
}

export const usuariosInitialState: UsuariosState = {
    users  : [],
    loaded : false,
    loading: false,
    error  : null
}
const _UsuarioReducer = createReducer( usuariosInitialState,

    on( userActions.UPLOAD_USERS_ID, (state, { id }) => ({ 
        ...state, 
        loading: true,
        id: id
    })),
    
    on( userActions.UPLOAD_USERS_ID_SUCCESS, (state, { usuarios }) => ({ 
        ...state, 
        loading: false,
        loaded: true,
        user: { ...usuarios }
    })),

    on( userActions.UPLOAD_USERS_ID_ERROR, (state, { payload }) => ({ 
        ...state, 
        loading: false,
        loaded: false,
        error: {
            url: payload.url,
            name: payload.name,
            message: payload.message
        }
    })),

);

export function UsuarioReducer(state, action) {
    return _UsuarioReducer(state, action);
}