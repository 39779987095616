<app-breadcrumb
  [title]="'Product'"
  [items]="['Apps', 'Ecommerce']"
  [active_item]="'Product'"
></app-breadcrumb>
<!-- Container-fluid starts-->
<div class="container-fluid product-wrapper" [class.sidebaron]="openSidebar">
  <div class="product-grid">
    <div class="feature-products">
      <div class="row">
        <div class="col-md-6 products-total">
          <div
            class="square-product-setting d-inline-block"
            (click)="toggleListView(false)"
          >
            <a class="icon-grid grid-layout-view" href="javascript:void(0);">
              <app-feather-icons
                class="close-btn"
                [icon]="'grid'"
              ></app-feather-icons>
            </a>
          </div>
          <div
            class="square-product-setting d-inline-block"
            (click)="toggleListView(true)"
          >
            <a
              class="icon-grid m-0 list-layout-view"
              href="javascript:void(0);"
            >
              <app-feather-icons
                class="close-btn"
                [icon]="'list'"
              ></app-feather-icons>
            </a>
          </div>
          <span
            class="d-none-productlist filter-toggle"
            (click)="sidebarToggle()"
          >
            Filters
            <span class="ms-2">
              <i class="toggle-data" data-feather="chevron-down"></i>
            </span>
          </span>
          <div class="d-inline-block">
            <ul>
              <li (click)="grid2s()">
                <a class="product-2-layout-view" href="javascript:void(0);"
                  ><span class="line-grid line-grid-1 bg-primary"></span
                  ><span class="line-grid line-grid-2 bg-primary"></span
                ></a>
              </li>
              <li (click)="grid3s()">
                <a class="product-3-layout-view" href="javascript:void(0);"
                  ><span class="line-grid line-grid-3 bg-primary"></span
                  ><span class="line-grid line-grid-4 bg-primary"></span
                  ><span class="line-grid line-grid-5 bg-primary"></span
                ></a>
              </li>
              <li (click)="gridOpens()">
                <a class="product-4-layout-view" href="javascript:void(0);"
                  ><span class="line-grid line-grid-6 bg-primary"></span
                  ><span class="line-grid line-grid-7 bg-primary"></span
                  ><span class="line-grid line-grid-8 bg-primary"></span
                  ><span class="line-grid line-grid-9 bg-primary"></span
                ></a>
              </li>
              <li (click)="grid6s()">
                <a class="product-6-layout-view" href="javascript:void(0);"
                  ><span class="line-grid line-grid-10 bg-primary"></span
                  ><span class="line-grid line-grid-11 bg-primary"></span
                  ><span class="line-grid line-grid-12 bg-primary"></span
                  ><span class="line-grid line-grid-13 bg-primary"></span
                  ><span class="line-grid line-grid-14 bg-primary"></span
                  ><span class="line-grid line-grid-15 bg-primary"></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 text-sm-end "  *ngFor="let pag of pag$">
          <span class="f-w-600 m-r-5"
            >Showing Products {{ (pag?.pageNumber  ) }} - {{ pag?.pageSize  }} Of {{ pag?.count  }} Results</span
          >
          <div class="select2-drpdwn-product select-options d-inline-block">
            <select class="form-control btn-square" name="select">
              <option value="opt1">Featured</option>
              <option value="opt2">Lowest Prices</option>
              <option value="opt3">Highest Prices</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-md-4">
          <div class="product-sidebar">
            <div class="filter-section">
              <div class="card">
                <div class="card-header" (click)="sidebarToggle()">
                  <h6 class="mb-0 f-w-600">
                    Filters<span class="pull-right"
                      ><i class="fa fa-chevron-down toggle-data"></i
                    ></span>
                  </h6>
                </div>
                <div class="left-filter">
                  <div class="card-body filter-cards-view animate-chk">
                    <div class="product-filter">
                      <h6 class="f-w-600">Models</h6>
                      <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      (change)="onOptionSelected($event,'Category')"
                      [(ngModel)]="selectedOption"
                    >
                      <mat-radio-button class="example-radio-button" *ngFor="let category of category$" [value]="category">
                        {{category?.name}}
                      </mat-radio-button>
                    </mat-radio-group>
                    <button
                mat-fab
                color="primary"
                aria-label="Example icon button with a delete icon"
                (click)="clearSelection($event)"
                [ariaLabel]="'Category'"

              >
                <mat-icon>delete</mat-icon>
              </button>
                    </div>
                    <div class="product-filter">
                      <h6 class="f-w-600">Series</h6>
                      <mat-radio-group
                      aria-labelledby="example-radio-group-label"
                      class="example-radio-group"
                      (change)="onOptionSelected($event,'Series')"
                      [(ngModel)]="selectedOption2"

                    >
                      <mat-radio-button class="example-radio-button" *ngFor="let series of series$" [value]="series">
                        {{series?.name}}
                      </mat-radio-button>
                    </mat-radio-group>
                    </div>
                    <button
                    mat-fab
                    color="primary"
                    aria-label="Example icon button with a delete icon"
                    [ariaLabel]="'Series'"
                    (click)="clearSelection($event)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                    <!---  <div class="product-filter slider-product">
                      <h6 class="f-w-600">Colors</h6>
                      <div class="color-selector">
                        <ul>
                          <li class="white"></li>
                          <li class="gray"></li>
                          <li class="black"></li>
                          <li class="orange"></li>
                          <li class="green"></li>
                          <li class="pink"></li>
                          <li class="yellow"></li>
                          <li class="blue"></li>
                          <li class="red"></li>
                        </ul>
                      </div>
                    </div> -->
                    <div class="product-filter text-center">
                      <img
                        class="img-fluid banner-product"
                        src="https://www.q-pumps.com/img/productos/QIS%2011%20FEB%202020.png"
                        alt=""
                        data-original-title=""
                        title=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-md-8">
          <form [formGroup]="searchForm">
            <div class="form-group m-0">
              <input
                class="form-control"
                type="search"
                formControlName="search"
                placeholder="Search.."
                [value]="search$ | async"
                (keyup)="searchProduct()"
                data-original-title=""
                title=""
              /><i (click)="searchProduct()" class="fa fa-search"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="product-wrapper-grid" [class.list-view]="listView">
      <div class="row">
        <div
          [class.col-xl-3]="col_xl_3"
          [class.col-xl-4]="xl_4"
          [class.col-sm-3]="col_sm_3"
          [class.col-xl-4]="col_xl_4"
          [class.col-sm-4]="col_sm_4"
          [class.col-xl-6]="col_xl_6"
          [class.col-sm-6]="col_sm_6"
          [class.col-xl-12]="col_xl_12"
          [class.col-xl-2]="col_xl_2"
          *ngFor="let product of products$ "
        >
          <div class="card">
            <div class="product-box card-product">
              <div class="product-img">
                <div
                  class="ribbon"
                  [ngClass]="
                    (product?.activo_bomba ) == 0
                      ? 'ribbon-success'
                      : 'ribbon-danger'
                  "
                  *ngIf="product?.status >= 0"
                >
                  {{ product?.status !== 0 ? "Activo" : "Agotado" }}
                </div>
                <div
                  class="ribbon ribbon-success ribbon-right"
                  *ngIf="(product?.activo_bomba ) >= 0"
                >
                  {{ product?.price }}
                </div>
                <img
                  class="img-fluid"
                  [src]="product?.productPicture "
                  alt=""
                />
                <div class="product-hover">
                  <ul>
                    <li>
                      <a (click)="UploadCart(product)" [routerLink]="['/ecommerce/cart']"
                        ><i class="icon-shopping-cart"></i
                      ></a>
                    </li>
                    <li>
                      <a (click)="openProductDetail(content, product)"
                        ><i class="icon-eye"></i
                      ></a>
                    </li>
                    <ng-template #content let-modal>
                      <div class="modal-body" style="padding: 0%">
                        <app-quick-view
                          [productDetail]="productDetail"
                        ></app-quick-view>
                        <button
                          type="button"
                          class="btn-close"
                          aria-label="Close"
                          (click)="modal.dismiss('Cross click')"
                        ></button>
                      </div>
                    </ng-template>
                  </ul>
                </div>
              </div>

              <!--- aqui comienza el  modal-->
              <div class="modal fade" id="exampleModalCenter16">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <div class="product-box row">
                        <div class="product-img col-lg-6">
                          <img
                            class="img-fluid"
                            [src]="product?.productPicture "
                            alt=""
                          />
                        </div>
                        <div class="product-details col-lg-6 text-start">
                          <a href="e-commerce/product-page">
                            <h4>{{ product?.name  }}</h4>
                          </a>
                          <div class="product-price">
                            ${{ product?.price  }}
                            <del>$ {{ product?.price  }}</del>
                          </div>
                          <div class="product-view">
                            <h6 class="f-w-600">Product Details</h6>
                            <p class="mb-0">
                              {{ product?.description  }}
                            </p>
                          </div>
                          <div class="product-qnty">
                            <h6 class="f-w-600">Quantity</h6>
                            <fieldset>
                              <div class="input-group">
                                <input
                                  class="touchspin text-center"
                                  type="text"
                                  value="5"
                                />
                              </div>
                            </fieldset>
                            <div class="addcart-btn">
                              <a
                                class="btn btn-primary me-3"
                                href="e-commerce/cart"
                                >Add to Cart </a
                              ><a
                                class="btn btn-primary"
                                href="e-commerce/product-page"
                                >View Details</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn-close"
                        type="button"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="product-details">
                <a href="ecommerce/product-page">
                  <h4>{{ product?.Name  }}</h4>
                </a>
                <p>{{ product.Description  }}</p>
                <div class="product-price">
                  ${{ product?.price  }}
                  <del>${{ product?.price  }}</del>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
