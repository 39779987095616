import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ProductState } from "../reducers/products.reducer";
import { IChangeModel, IPagniation } from '../../shared/Models/Pagniation';
import { ShopParamsProduct } from 'src/app/shared/Models/shopParams';
import { IProducts } from 'src/app/shared/Models/Products';

export const selectProductState = createFeatureSelector<ProductState>('products');

export const selectProducts = createSelector(
  selectProductState,
(state :ProductState) : IProducts[] => state.products
);
export const selectProductSelected = createSelector(
  selectProductState,
(state :ProductState) : IProducts => state.product
);
export const selectProductsPagination = createSelector(
  selectProductState,
  (state :ProductState): IPagniation[]  => state.pagination
);
export const selectProductsLoading = createSelector(
  selectProductState,
  (state :ProductState) :boolean=> state.loading
);
export const selectProductsParameters = createSelector(
  selectProductState,
  (state: ProductState) : ShopParamsProduct => state.parameters
);
export const selectParameterSearch = createSelector(
  selectProductsParameters,
  (state: ShopParamsProduct) : string => state.search
);
export const selectProductsSwitch = createSelector(
  selectProductState,
  (state:ProductState): IChangeModel  => state.changeModels
);
export const selectProductsLoaded = createSelector(
  selectProductState,
  (state :ProductState) :boolean=> state.loaded
);
export const selectProductsError = createSelector(
  selectProductState,
  (state :ProductState):string => state.error
);
