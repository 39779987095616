import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SeriesState } from "../reducers";
import { ISeries } from '../../shared/Models/ISeries';

export const selectSerieState = createFeatureSelector<SeriesState>('series');

export const selectSerie = createSelector(
    selectSerieState,
  (state: SeriesState) : ISeries[] => state.series
);
export const selectSerieSelected = createSelector(
    selectSerieState,
  (state: SeriesState) : ISeries => state.serieSelected
);
export const selectSerieLoading = createSelector(
    selectSerieState,
  (state: SeriesState):boolean => state.loading
);
export const selectSerieError = createSelector(
    selectSerieState,
  (state: SeriesState): string => state.error
);