export class ShopParams {
    sort: string = 'Name';
    pageNumber:number=1;
    pageSize:number = 3;
    search:string ='';
}
export class ShopParamsProduct  extends ShopParams {
    categoryId: number = 0;
    serieId: number = 0;
}
export class ShopParamsSeries  {
    Id: number = 0;
}



