import { createAction, props } from '@ngrx/store';
import {  IProducts } from '../../shared/Models/Products';
import {   ShopParamsProduct } from 'src/app/shared/Models/shopParams';
import { IPagniationProduct } from 'src/app/shared/Models/Pagniation';
import { ICategory } from 'src/app/shared/Models/Category';
import { ISeries } from 'src/app/shared/Models/ISeries';

let ApiActions:string ='Products'

export const UPLOAD_PRODUCT_ID = createAction(
    '['+{ApiActions}+'] Upload Product ID',
    props<{ id: number }>()
);

export const UPLOAD_PRODUCT_ID_SUCCESS = createAction(
    '['+{ApiActions}+'] Upload Product ID Success',
    props<{ product: IProducts }>()
);

export const UPLOAD_PRODUCT_ID_ERROR = createAction(
    '['+{ApiActions}+'] Upload Product ID Error',
    props<{ payload: any }>()
);

export const UPLOAD_ALL_PRODUCTS = createAction(
    '['+{ApiActions}+'] Start upload all Products',
     props<{ param: ShopParamsProduct }>()
);
export const SEARCH_PRODUCTS = createAction(
    '['+{ApiActions}+'] Search in all Products',
     props<{ search: string }>()
);
export const ACTIVE_UPLOAD_PRODUCTS = createAction(
    '['+{ApiActions}+'] Active Upload all Products',
    props<{ model: ICategory | ISeries }>()
);  
export const ACTIVE_SEARCH_PRODUCTS = createAction(
    '['+{ApiActions}+'] Active Search all Products',
);  
export const CHANGES_PRODUCTS_FOR_CATEGORY = createAction(
    '['+{ApiActions}+'] Changes products for category',
     props<{ param: ShopParamsProduct }>()
);
export const CHANGES_MODELS_FOR_CATEGORY = createAction(
    '['+{ApiActions}+'] Changes models for category',
     props<{ param: boolean }>()
);
export const CHANGES_MODELS_FOR_SERIES = createAction(
    '['+{ApiActions}+'] Changes models for series',
     props<{ param: boolean }>()
);
export const CHANGES_PARAMETERS_PRODUCTS = createAction(
    '['+{ApiActions}+'] Changes default parameters products',
    );

export const CHANGES_PRODUCTS_FOR_SERIES = createAction(
    '['+{ApiActions}+'] Changes products for series',
     props<{ param: ShopParamsProduct }>()
);
export const UPLOAD_ALL_PRODUCT_SUCCESS = createAction(
    '['+{ApiActions}+'] Upload all Products Success',
    props<{ IProducts: IPagniationProduct }>()
);

export const UPLOAD_ALL_PRODUCT_ERROR = createAction(
    '['+{ApiActions}+'] Upload all Products Error',
    props<{ payload: any }>()
);
export const SELECTED_PRODUCT = createAction(
    '['+{ApiActions}+'] Select Product to list',
     props<{ product: IProducts }>()
);
export const SELECTED_CHARACTERISTICS= createAction(
    '['+{ApiActions}+'] Active characteristics product',
);
export const UPLOAD_CHARACTERISTICS_PRODUCT_CATEGORY = createAction(
    '['+{ApiActions}+'] Upload characteristics category product',
    props<{ charact: ICategory }>()
);
export const UPLOAD_CHARACTERISTICS_PRODUCT_SERIES = createAction(
    '['+{ApiActions}+'] Upload characteristics series product',
    props<{ charact: ISeries }>()
);
